// import $ from "../shared/jquery-global";

import { applySlimSelectAutocomplete, performSlimSearch } from "../shared/slim-select-wrapper";

// import { loadSelect2 } from "../shared/load-dynamic";

const elementsFromSelectorOrThis = (elementSelector, element) => {
  let elements = []
  if (!elementSelector) {
    elements = [element];
  } else {
    elements = document.querySelectorAll(elementSelector)
  }
  return elements
}

function productAutocompleteShopAdmin(elementSelector) {
  elementsFromSelectorOrThis(elementSelector, this).forEach(applyShopAdminProductAutocomplete)
}
const applyShopAdminProductAutocomplete = (element) => {  
  const url = element.dataset.ajaxUrl || "/shop_admin/store_variants/autocomplete_products.json";

  applySlimSelectAutocomplete({
    element,
    urlPath: url,
    formatRecord: (product) => ({
      text: product.name,
      value: product.id,
    }),
    getSearchParams: (search) => ({
      term: search
    }),
    responseDataKey: "products",
    minimumInputLength: 1
  })
};

const applyShopAdminVariantAutocomplete = (element) => {
  const url = element.dataset.ajaxUrl || "/shop_admin/variants.json";

  applySlimSelectAutocomplete({
    element,
    urlPath: url,
    formatRecord: (variant) => ({
      text: variant.name,
      value: variant.id,
    }),
    getSearchParams: (search) => ({
      term: search,
      "q[sku_or_upc_or_product_name_cont]": search
    }),
    responseDataKey: "variants",
    minimumInputLength: 1
  });
}

function variantAutocompleteShopAdmin(elementSelector) {
  elementsFromSelectorOrThis(elementSelector, this).forEach(applyShopAdminVariantAutocomplete)
};

function stockItemsAutocompleteShopAdmin(elementSelector) {
  elementsFromSelectorOrThis(elementSelector, this).forEach(applyShopAdminStockItemAutocomplete)
}
function storeVariantAutocompleteShopAdmin(elementSelector) {
  elementsFromSelectorOrThis(elementSelector, this).forEach(applyShopAdminStoreVariantAutocomplete)
}

const applyShopAdminStoreVariantAutocomplete = (element) => {
  const url = element.dataset.ajaxUrl || "/shop_admin/store_variants.json";

  applySlimSelectAutocomplete({
    element,
    urlPath: url,
    formatRecord: (storeVariant) => ({
      text: `${storeVariant.cached_display_name} - ${storeVariant.stock_items?.reduce((result, si) => si.count_on_hand + result, 0)}`,
      value: storeVariant.id,
    }),
    getInitialParams: () => ({
      "additional_fields": "stock_items"
    }),
    getSearchParams: (search) => ({
      term: search,
      "q[sku_or_upc_or_local_name_or_cached_display_name_or_product_name_cont]": search,
      "additional_fields": "stock_items"
    }),
    responseDataKey: "store_variants",
    minimumInputLength: 1
  }); 
};

const applyShopAdminStockItemAutocomplete = (element) => {
  const url = element.dataset.ajaxUrl || "/shop_admin/stock_items.json";

  const formatRecord = (stock_item) => ({
    text: `${stock_item.display_name} - ${stock_item.count_on_hand}`,
    value: stock_item.id,
  })
  const responseDataKey = "stock_items";
  let previousSelectedStockLocationId;
  const select = applySlimSelectAutocomplete({
    element,
    urlPath: url,
    formatRecord,
    getInitialParams: () => {
      const selectedStockLocationId = element.dataset.stockLocationSelector && document.querySelector(element.dataset.stockLocationSelector)?.value
      if(selectedStockLocationId) {
        return {
          "q[stock_location_id_eq]": selectedStockLocationId
        }
      } else {
        return {}
      }
    },
    getSearchParams: (search) => {
      const searchParams = {
        "q[store_variant_sku_or_store_variant_upc_or_store_variant_local_name_or_store_variant_cached_display_name_or_store_variant_product_name_cont]": search
      };
      const selectedStockLocationId = element.dataset.stockLocationSelector && document.querySelector(element.dataset.stockLocationSelector)?.value
      if(selectedStockLocationId) {
        searchParams["q[stock_location_id_eq]"] = selectedStockLocationId;
      }
      return searchParams;
    },
    responseDataKey,
    minimumInputLength: 1,
    events: {
      beforeOpen: () => {
        const selectedStockLocationId = element.dataset.stockLocationSelector && document.querySelector(element.dataset.stockLocationSelector)?.value
        if(previousSelectedStockLocationId && previousSelectedStockLocationId != selectedStockLocationId) {
          select.setData([]); // clear previous data
        }
        if(select.getData().length < 2 && selectedStockLocationId) {
          previousSelectedStockLocationId = selectedStockLocationId;

          const controller = new AbortController();
          const signal = controller.signal;

          return performSlimSearch({
            element, 
            signal, 
            urlPath: url,
            searchParams: {
              "q[stock_location_id_eq]": selectedStockLocationId
            },
            formatRecord,
            responseDataKey,
            currentData: []
          }).then((data) => {
            select.setData(data);
          })            
        }
      }
    }
  });
};
function storeVariantVariantAutocompleteShopAdmin(elementSelector) {
  elementsFromSelectorOrThis(elementSelector, this).forEach(applyShopAdminStoreVariantVariantAutocomplete)
}

const applyShopAdminStoreVariantVariantAutocomplete = (element) => {
  const url = element.dataset.ajaxUrl || "/shop_admin/store_variants.json";

  applySlimSelectAutocomplete({
    element,
    urlPath: url,
    formatRecord: (storeVariant) => ({
      text: storeVariant.cached_display_name,
      value: storeVariant.variant_id
    }),
    getSearchParams: (search) => ({
      "q[sku_or_upc_or_local_name_or_cached_display_name_or_product_name_cont]": search,
      "term": search, // for backward compatibility, but can be removed if autocomplete of api/v2 is not used         
    }),
    responseDataKey: "store_variants",
    minimumInputLength: 1
  }); 
};

function storeServiceAreaAutoCompleteShopAdmin(elementSelector) {
  elementsFromSelectorOrThis(elementSelector, this).forEach(applyShopAdminStoreServiceAreaAutoComplete)
}

const applyShopAdminStoreServiceAreaAutoComplete = (element) => {
  const url = element.dataset.ajaxUrl || "/shop_admin/service_areas/autocomplete.json";

  applySlimSelectAutocomplete({
    element,
    urlPath: url,
    formatRecord: (serviceArea) => ({
      text: serviceArea.name,
      value: serviceArea.id
    }),
    getSearchParams: (search) => ({
      "term": search, // for backward compatibility, but can be removed if autocomplete of api/v2 is not used         
    }),
    responseDataKey: "service_areas",
    minimumInputLength: 1
  }); 
};

// const storeVariantProductAutocompleteShopAdmin = function(element) {
//   if (!element) {
//     element = this;
//   }
//   if ($(element).length < 1) {
//     return;
//   }
//   const url = element.dataset.ajaxUrl || "/shop_admin/store_variants.json";
//   loadSelect2().then(() => {
//     $(element).select2({
//       minimumInputLength: 3,
//       width: "300px",
//       ajax: {
//         url,
//         dataType: "json",
//         type: "GET",
//         quietMillis: 50,
//         cache: true,
//         headers: {
//           "Authorization": `Bearer ${ Rails.access_token }`,
//         },
//         data: (params) => {
//           return {
//             term: params.term,
//             store_id: element.dataset.storeId,
//           };
//         },
//         processResults: (data, params) =>{
//           return {
//             results: data.store_variants.map((store_variant) => ({
//               text: store_variant.text,
//               id: store_variant.product_id,
//               name: store_variant.text,
//             })),
//           };
//         },
//         formatResult(item) {
//           return item.name;
//         },
//       },
//       formatSelection(item) {
//         return item.name;
//       },
//     });
//   });
// };
function userAutocompleteShopAdmin(elementSelector) {
  elementsFromSelectorOrThis(elementSelector, this).forEach(applyShopAdminUserAutocomplete)  
}
const applyShopAdminUserAutocomplete = (element) => {
  const url = element.dataset.ajaxUrl || "/shop_admin/users.json";

  applySlimSelectAutocomplete({
    element,
    urlPath: url,
    formatRecord: (user) => ({
      text: user.text || `${[user.first_name, user.last_name].filter(v => !!v).join(" ")} ${user.email}`,
      value: user.id,
    }),
    getSearchParams: (search) => ({
      "q[first_name_or_last_name_or_email_cont]": search,
      term: search
    }),
    responseDataKey: "users",
    minimumInputLength: 1
  });
};

const applyShopAdminCustomerAutocomplete = (element) => {
  const url = element.dataset.ajaxUrl || "/shop_admin/customers.json";

  applySlimSelectAutocomplete({
    element,
    urlPath: url,
    formatRecord: (customer) => ({
      text: customer.display_name,
      value: customer.id
    }),
    getSearchParams: (search) => ({
      "q[first_name_or_last_name_or_email_or_phone_cont]": search,
    }),
    responseDataKey: "customers",
    minimumInputLength: 1
  });  
}
function customerAutocompleteShopAdmin(elementSelector) {
  elementsFromSelectorOrThis(elementSelector, this).forEach(applyShopAdminCustomerAutocomplete)
};

const applyShopAdminTaxonAutocomplete = (element) => {
  const url = element.dataset.ajaxUrl || "/api/v2/taxons.json";

  applySlimSelectAutocomplete({
    element,
    urlPath: url,
    formatRecord: (taxon) => ({
      text: (taxon.pretty_name || taxon.permalink.replace("/", " -> ")).replace(/^.*?-\>\s*/, "") || taxon.name, //remove first level category
      value: taxon.id
    }),
    getSearchParams: (search) => ({
      "q[name_cont]": search,
      "q[parent_id_not_null]": true
    }),
    responseDataKey: "taxons",
    minimumInputLength: 1
  });
}

const taxonAutocompleteShopAdmin = function(elementSelector) {
  elementsFromSelectorOrThis(elementSelector, this).forEach(applyShopAdminTaxonAutocomplete)
};

const applyOptionValuesAutocomplete = (element, createNew = false) => {
  const url = element.dataset.ajaxUrl || "/api/v2/option_values";
  applySlimSelectAutocomplete({
    element,
    urlPath: url,
    formatRecord: (optionValue) => ({
      text: optionValue.presentation_with_option_type,
      value: optionValue.id,
    }),
    getSearchParams: (search) => ({
      "q[name_or_option_type_name_cont]": search,
    }),
    responseDataKey: "option_values",
    minimumInputLength: 1,
    createNew
  });
}
function optionValuesAutocomplete(elementSelector, createNew = false) {
  elementsFromSelectorOrThis(elementSelector, this).forEach( element => 
    applyOptionValuesAutocomplete(element, createNew)
  )
};

const applyShopAdminOrderAutocomplete = (element) => {
  const url = element.dataset.ajaxUrl || "/shop_admin/orders.json";

  applySlimSelectAutocomplete({
    element,
    urlPath: url,
    formatRecord: (order) => ({
      text: order.number,
      value: order.id
    }),
    getSearchParams: (search) => ({
      "q[number_cont]": search,
    }),
    responseDataKey: "orders",
    minimumInputLength: 1
  });
}
function orderAutocompleteShopAdmin(elementSelector) {
  elementsFromSelectorOrThis(elementSelector, this).forEach(applyShopAdminOrderAutocomplete)
};

document.addEventListener("turbo:load", function() {
  productAutocompleteShopAdmin(".product-picker-shop-admin");
  variantAutocompleteShopAdmin(".variant-picker-shop-admin");
  storeVariantAutocompleteShopAdmin(".store-variant-picker-shop-admin");
  storeVariantVariantAutocompleteShopAdmin(".store-variant-variant-picker-shop-admin");
  // storeVariantProductAutocompleteShopAdmin(".store-variant-product-picker-shop-admin");
  orderAutocompleteShopAdmin(".order-picker-shop-admin");
  userAutocompleteShopAdmin(".user-picker-shop-admin");
  optionValuesAutocomplete(".option-value-picker-shop-admin");
  optionValuesAutocomplete(".option-value-picker-create", true);
  taxonAutocompleteShopAdmin(".taxon-picker-shop-admin");
  customerAutocompleteShopAdmin(".customer-picker-shop-admin");
  storeServiceAreaAutoCompleteShopAdmin(".store-service-area-auto-complete");
  stockItemsAutocompleteShopAdmin(".stock-item-picker-shop-admin");
});

window.productAutocompleteShopAdmin = productAutocompleteShopAdmin; //for create.js.erb
window.storeVariantAutocompleteShopAdmin = storeVariantAutocompleteShopAdmin;
window.stockItemsAutocompleteShopAdmin = stockItemsAutocompleteShopAdmin; 
window.userAutocompleteShopAdmin = userAutocompleteShopAdmin;
window.taxonAutocompleteShopAdmin = taxonAutocompleteShopAdmin;
window.storeVariantVariantAutocompleteShopAdmin = storeVariantVariantAutocompleteShopAdmin;
// window.storeVariantProductAutocompleteShopAdmin = storeVariantProductAutocompleteShopAdmin;

window.customerAutocompleteShopAdmin = customerAutocompleteShopAdmin;
// window.storeVariantProductAutocompleteShopAdmin = storeVariantProductAutocompleteShopAdmin;
window.storeServiceAreaAutoCompleteShopAdmin = storeServiceAreaAutoCompleteShopAdmin;
